var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),(_vm.data)?_c('div',[_c('Header',[_c('img',{staticStyle:{"max-width":"80%","max-height":"80%"},attrs:{"slot":"logo","src":require("../../assets/images/logo/Logo-Inflow-Hor-BB@2x.png")},slot:"logo"})]),(_vm.data.attributes.media.data)?_c('div',{staticClass:"rn-page-title-area pt--120 pb--190 bg_image bg_image--7",style:({
        backgroundImage:
          'url(' +
          _vm.data.attributes.media.data[0].attributes.formats.large.url +
          ')',
      }),attrs:{"data-black-overlay":"8"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"rn-page-title text-center pt--100"},[_c('h2',{staticClass:"headingtitle theme-gradient"},[_vm._v(" "+_vm._s(_vm.data.attributes.name)+" ")])])])],1)],1)],1):_c('div',{staticClass:"rn-page-title-area pt--120 pb--190 bg_image bg_image--7",style:({
        backgroundImage:
          'url(' +
          'https://strapi-3-inflow-assets.s3.us-west-2.amazonaws.com/medium_wallpaperbetter_e1ef22eeee.jpg' +
          ')',
      }),attrs:{"data-black-overlay":"8"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"rn-page-title text-center pt--100"},[_c('h2',{staticClass:"headingtitle theme-gradient"},[_vm._v(" "+_vm._s(_vm.data.attributes.name)+" ")])])])],1)],1)],1),_c('div',{staticClass:"rn-portfolio-area rn-section-gap bg_color--5"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"lg":"12"}},[_c('div',{staticClass:"section-title text-center mb--20 mb_sm--0"},[_c('h2',{staticClass:"heading-title"},[_vm._v("Productos")]),_c('p',[_vm._v(" "+_vm._s(_vm.data.attributes.description)+" ")])])])],1),_c('ServiceFive',{attrs:{"products":_vm.data.attributes.products.data}})],1)],1),_c('Footer')],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }