<template>
  <v-row>
    <v-col
      md="3"
      sm="6"
      cols="12"
      class="mt--30"
      v-for="(p, i) in product"
      :key="i"
    >
      <div class="standard-service" v-if="products">
        <div class="thumbnai">
          <img :src="p.src" alt="Corporate Images" />
        </div>
        <div class="content">
          <h3 v-if="p.active">
            <router-link :to="'/productos/' + p.slug">{{
              p.title
            }}</router-link>
          </h3>
          <h3 v-else>
            {{
              p.title
            }}
          </h3>
          <p>
            {{ p.description }}
          </p>
          <router-link
            v-if="p.active"
            class="btn-transparent rn-btn-dark"
            :to="'/productos/' + p.slug"
            ><span class="text">Detalles</span></router-link
          >
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["products"],
  data() {
    return {
      product: [],
    };
  },
  mounted() {
    if (this.products) this.getProducts();
  },
  methods: {
    async getProducts() {
      await this.products.forEach((element) => {
        if (element.attributes.media.data) {
          this.product.push({
            id: element.id,
            src: element.attributes.media.data[0].attributes.url,
            description: element.attributes.short_description,
            title: element.attributes.name,
            slug: element.attributes.slug,
          });
        } else {
          this.product.push({
            id: element.id,
            src: "https://strapi-3-inflow-assets.s3.us-west-2.amazonaws.com/medium_wallpaperbetter_e1ef22eeee.jpg",
            description: element.attributes.short_description,
            title: element.attributes.name,
            slug: element.attributes.slug,
            active: element.attributes.active,
          });
        }
      });
    },
  },
};
</script>
