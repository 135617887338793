<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div v-if="data">
      <!-- Start Header Area -->
      <Header>
        <img
          slot="logo"
          src="../../assets/images/logo/Logo-Inflow-Hor-BB@2x.png"
          style="max-width: 80%; max-height: 80%"
        />
      </Header>
      <!-- End Header Area -->

      <!-- Start Breadcrump Area  -->

      <div
        class="rn-page-title-area pt--120 pb--190 bg_image bg_image--7"
        data-black-overlay="8"
        v-if="data.attributes.media.data"
        :style="{
          backgroundImage:
            'url(' +
            data.attributes.media.data[0].attributes.formats.large.url +
            ')',
        }"
      >
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="rn-page-title text-center pt--100">
                <h2 class="headingtitle theme-gradient">
                  {{ data.attributes.name }}
                </h2>
                <!-- <p>{{ data.attributes.description }}</p> -->
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div
        class="rn-page-title-area pt--120 pb--190 bg_image bg_image--7"
        data-black-overlay="8"
        v-else
        :style="{
          backgroundImage:
            'url(' +
            'https://strapi-3-inflow-assets.s3.us-west-2.amazonaws.com/medium_wallpaperbetter_e1ef22eeee.jpg' +
            ')',
        }"
      >
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="rn-page-title text-center pt--100">
                <h2 class="headingtitle theme-gradient">
                  {{ data.attributes.name }}
                </h2>
                <!-- <p>{{ data.attributes.description }}</p> -->
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- End Breadcrump Area  -->

      <div class="rn-portfolio-area rn-section-gap bg_color--5">
        <v-container>
          <v-row>
            <v-col lg="12">
              <div class="section-title text-center mb--20 mb_sm--0">
                <h2 class="heading-title">Productos</h2>
                <p>
                  {{ data.attributes.description }}
                </p>
              </div>
            </v-col>
          </v-row>
          <!-- End .row -->

          <ServiceFive :products="data.attributes.products.data" />
          <!-- <v-row class="justify-center">
            <div v-if="data">
              <v-chip
                class="ma-4"
                v-for="(chip, i) in data.categorias"
                :key="i"
              >
                {{ chip.titulo }}
              </v-chip>
            </div>
          </v-row> -->
        </v-container>
      </div>

      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import PortfolioFour from "../../components/portfolio/PortfolioFour.vue";
import ServiceFive from "../../components/service/ServiceFive.vue";
import axios from "axios";
export default {
  components: {
    Header,
    Footer,
    PortfolioFour,
    ServiceFive,
  },
  data() {
    return {
      overlay: true,
      data: null,
      items: [
        {
          thumb: require("../../assets/images/service/service-02.png"),
          src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
        },
      ],
      index: null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        const r = await axios(
          `${this.$apiUrl}/api/product-lines/?filters\[slug\]\[$eq\]=${this.$route.params.slug}&populate[0]=media&populate[1]=products&populate[2]=products.media`
        );
        this.data = r.data.data[0];
        this.overlay = false;
      } catch (err) {
        console.log(err);
        this.overlay = false;
      }
    },
  },
};
</script>
